import React from "react";
import "../styles/Specifics.css"

const content = {
    title: {fr: "Détails", en: "Details"},
    titles: {
        bathroom: {fr: "Salle de bain", en: "Bathroom"},
        notIncluded: {fr: "Non inclus", en: "Not included"},
        bedroom: {fr: "Chambre et buanderie", en: "Bedroom and laundry"},
        entertainment: {fr: "Divertissement", en: "Entertainment"},
        kitchen: {fr: "Cuisine", en: "Kitchen"},
        outdoor: {fr: "Extérieur", en: "Outdoor"},
        services: {fr: "Services", en: "Services"}
    }
}


import {infos} from "../datas/infos";


function Specifics({language}) {

    const bathroom = infos.details.bathroom.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const bedroom = infos.details.bedroom.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const kitchen = infos.details.kitchen.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const outdoor = infos.details.outdoor.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const entertainment = infos.details.entertainment.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const services = infos.details.services.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    const notIncluded = infos.details.notIncluded.map((item, index) =>
        <p key={index}>• {item[language.index]}</p>)

    return (
        <div id={"specifics-container"}>
            <h3>{content.title[language.index]}</h3>

            <div id={"specifics-content"}>
                <div className={"specifics-element"}>
                    <h5>{content.titles.bathroom[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {bathroom}
                    </div>
                </div>



                <div className={"specifics-element"}>
                    <h5>{content.titles.bedroom[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {bedroom}
                    </div>
                </div>


                <div className={"specifics-element"}>
                    <h5>{content.titles.kitchen[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {kitchen}
                    </div>
                </div>

                <div className={"specifics-element"}>
                    <h5>{content.titles.outdoor[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {outdoor}
                    </div>
                </div>


                <div className={"specifics-element"}>
                    <h5>{content.titles.entertainment[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {entertainment}
                    </div>
                </div>


                <div className={"specifics-element"}>
                    <h5>{content.titles.services[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {services}
                    </div>
                </div>


                <div className={"specifics-element"}>
                    <h5>{content.titles.notIncluded[language.index]}</h5>
                    <div className={"specifics-element-content"}>
                        {notIncluded}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Specifics;
