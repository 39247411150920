import React from "react";
import "../styles/Footer.css"

const footerContent = {
    copyright: {fr: "Tous droits réservés",
                en: "All rights reserved"},
}


function Footer({language}) {
    return (
        <footer id={"footer-container"}>
            <p>© {footerContent.copyright[language.index]}</p>
        </footer>
    );
}

export default Footer;
