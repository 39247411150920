import React from "react";
import "../styles/Map.css"

const content = {
    title: {fr: "Localisation", en: "Location"},
}


function Map({language}) {
    const iframeSource = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d100306.86910675556!2d7.139160784841709!3d43.702009943820784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd304d8e7922f%3A0x285f9a803f5e603c!2s3051%20Rte%20des%20Serres%2C%2006570%20Saint-Paul-de-Vence!5e0!3m2!1sfr!2sfr!4v1663619363388!5m2!1sfr!2sen" width="100%" height="400" style="border:0; border-radius:10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
    return (
        <div id={"map-container"}>
            <h3>{content.title[language.index]}</h3>
            <div id={"map-content"} dangerouslySetInnerHTML={{__html: iframeSource}}/>
        </div>
    );
}

export default Map;
