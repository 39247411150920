import React from "react";
import "../styles/Infos.css"

const content = {
    title: {fr: "Informations générales", en: "General information"},
    annulation :{fr: "Politique d'annulation", en: "Cancellation Policy"},
    annulationContent: {fr: "Annulation possible 1 mois avant (remboursement de 50%)", en: "Cancellation possible 1 month before (50% refund)"}
}

import {infos} from "../datas/infos";



function Infos({language}) {

    const elements = infos.infos.map((item, index) =>
            <p key={index}>• {item[language.index]}</p>)

    return (
        <div id={"infos-container"}>
            <h3>{content.title[language.index]}</h3>

            <div id={"infos-content"}>
                <div className={"infos-element"}>
                    <h5>Infos</h5>
                    <div className={"infos-element-content"}>
                        {elements}
                    </div>
                </div>

                <div className={"infos-element"}>
                    <h5>{content.annulation[language.index]}</h5>
                    <div className={"infos-element-content"}>
                        <p>{content.annulationContent[language.index]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Infos;
