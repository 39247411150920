export const infos = {
    main: {
        guest : 2,
        bath: 1,
        bed: 1,
        kitchen: 1
    },


    infos: [
        {fr: "Surface : 24m2", en: "Surface : 24m2"},
        {fr: "Piscine extérieur au sel partagée - ouvert de 9h00 à 21h00", en: "Shared outdoor salt pool - open from 9:00 a.m. to 9:00 p.m."},
        {fr: "Arrivée : 13h00", en: "Arrival : 13:00 PM"},
        {fr: "Salle de douche avec WC", en: "Shower room with WC"},
        {fr: "Accès internet", en: "Internet access"},
        {fr: "Climatisation - chauffage", en: "Air conditioning - heating"},
        {fr: "Draps et linge de toilette fournis", en: "Bed sheets and towels included"},
        {fr: "Télévision", en: "Television"},
        {fr: "Espace cuisine", en: "Kitchen area"},
        {fr: "Jardin privatif avec table et transats", en: "Private garden with table and deckchairs"},
        {fr: "Parking privé clos", en: "Enclosed private car park"},
        {fr: "Entrée privée", en: "Private entrance"}
    ],

    details: {
        bathroom: [
            {fr: "Séche cheveux", en: "Hair dryer"},
            {fr: "Produits de néttoyages", en: "Cleaning products"},
            {fr: "Savon pour le corps", en: "Body soap"},
            {fr: "Eau chaude", en: "Hot water"},
            {fr: "Shampoing", en: "Shower gel"},
            {fr: "Papier toilette", en: "Toilet paper"}
        ],
        bedroom: [
            {fr: "Cintres", en: "Hangers"},
            {fr: "Draps", en: "Bed linens"},
            {fr: "Oreillers et couvertures", en: "Extra pillows and blankets"},
            {fr: "Fer à repasser", en: "Iron"},
            {fr: "Séche linge", en: "Drying rack for clothing"},
            {fr: "Rangement des vêtements : dressing", en: "Clothing storage: walk-in closet"},
            {fr: "Berceau de voyage", en: "Travel crib"}
        ],
        kitchen: [
            {fr: "2 plaques de cuisson", en: "Baking sheet"},
            {fr: "Barbecue électrique et ustensiles : Grill, charbon de bois, brochettes, etc.", en: "Barbecue and utensils: Grill, charcoal, bamboo skewers/iron skewers, etc."},
            {fr: "Table à manger", en: "Dining table"},
            {fr: "Réfrigérateur", en: "Refrigerator"},
            {fr: "Les bases de la cuisine : Casseroles et poêles, huile, sel et poivre", en: "Cooking basics: Pots and pans, oil, salt and pepper"},
            {fr: "Vaisselle et argenterie : Bols, couverts, assiettes, tasses, etc.", en: "Crockery and silverware: Bowls, cutlery, plates, cups, etc."},
            {fr: "Freezer", en: "Freezer"},
            {fr: "Bouilloir à eau chaude", en: "Hot water kettle"},
            {fr: "Cafetière - Nespresso", en: "Coffee maker: Nespresso"},
            {fr: "Verre à vin", en: "Wine glasses"},
            {fr: "Grille-pain", en: "Toaster"},
        ],
        outdoor: [
            {fr: "Salle à manger extérieure", en:"Outdoor dining area"},
            {fr: "Barbecue électrique", en: "Electric barbecue"},
            {fr: "Transats", en: "Deckchairs"},
            {fr: "Hamac", en: "Hammock"},
            {fr: "Jardin privée", en: "Private garden"},
        ],
        services: [
            {fr: "Dépôt de bagages avant l'heure d'arrivée autorisé", en: "Baggage deposit before check-in time allowed"},
            {fr: "Ménage avant l'arrivée", en: "Cleaning before checkout"},
        ],
        entertainment: [
            {fr: "Wifi 5GHz", en: "Wifi 5GHz"},
            {fr: "Connection ethernet", en: "Ethernet connection"},
            {fr: "43\" HDTV avec Fire TV", en: "43\" HDTV with Fire TV"},
            {fr: "Système audio Bluetooth", en: "Bluetooth sound system"},
            {fr: "Table de ping-pong", en: "Ping-pong table"},
            {fr: "Livres et matériel de lecture", en:"Books and reading material"}
        ],
        notIncluded: [
            {fr: "Machine à laver", en: "Washer"},
            {fr: "Détecteur de fumée", en: "Smoke alarm"}
        ]


    }

}