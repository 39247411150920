import React, {useState} from "react";
import "../styles/SwitchLanguage.css"
import button_next from "../assets/languages/button_next_grey.svg";

import {languages} from "../datas/languages";


function SwitchLanguage({ actualLanguage, setActualLanguage }) {
    const [displayOptions, setDisplayOptions] = useState(false);

    return (
        <div id={"switch-language--container"}>
            <div id={"switch-language--button"} onClick={() => setDisplayOptions(!displayOptions)}>
                <img className={"switch-language--flag"} src={actualLanguage.image} alt={actualLanguage.image.toString()}/>
                <p>{actualLanguage.name[actualLanguage.index]}</p>
                <img id={"switch-language--button-next"} src={button_next} alt={'button_next'} style={displayOptions ? {transform: 'rotate(-90deg)'} : null}/>
            </div>

            <div id={"switch-language--options"}  style={displayOptions ? {display: 'block'} : {display: 'none'}}>
                { languages.map((language, index) => (
                    <div key={index} onClick={() => {
                        setActualLanguage(language);
                        setDisplayOptions(false);
                    }}>
                        { language.index !== actualLanguage.index
                            ?   <div className={"switch-language--options-item"}>
                                    <img className={"switch-language--flag"} src={language.image} alt={language.image.toString()}/>
                                    <p>{language.name[actualLanguage.index]}</p>
                                </div>
                            : null
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SwitchLanguage;