import React from "react";
import "../styles/SwitchLanguage.css"
import '../styles/Header.css';


import logo from "../assets/logo.png";
import location from "../assets/location.png";

import {infos} from "../datas/infos";
import SwitchLanguage from "./SwitchLanguage";

const content = {
    guest: {fr: "personnes", en: "guests"},
    bed: {fr: "lit", en: "bed"},
    bath: {fr: "salle de bain", en: "bath"},
    kitchen: {fr: "cuisine", en: "kitchen"}

}


function Header({ language, setLanguage }) {

    return (
        <div id={"header-container"}>
            <div id={"header--logo"}>

                <div id={"header--logo-content"}>
                    <img src={logo} alt={"Logo"}/>

                    <div id={"header--logo-content-title"}>
                        <h2>La Colombe</h2>
                        <h3>chez Sandra</h3>
                    </div>
                </div>


                <a href={"#map-container"} id={"header--logo-content-location"}>
                    <img src={location} alt={"Location"}/>
                    <p>Saint Paul de Vence</p>
                </a>
            </div>


            <div id={"header-info"}>
                <p>{infos.main.guest} {content.guest[language.index]}</p>
                <div className={"header-info-separator"}>•</div>
                <p>{infos.main.bed} {content.bed[language.index]}</p>
                <div className={"header-info-separator"}>•</div>
                <p>{infos.main.bath} {content.bath[language.index]}</p>
                <div className={"header-info-separator"}>•</div>
                <p>{infos.main.kitchen} {content.kitchen[language.index]}</p>
            </div>

            <SwitchLanguage actualLanguage={language} setActualLanguage={setLanguage}/>

        </div>
    );
}

export default Header;