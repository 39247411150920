import Header from "./components/Header";
import {useState} from "react";
import {languages} from "./datas/languages";
import './styles/index.css';
import Presentation from "./components/Presentation";
import Footer from "./components/Footer";
import './styles/App.css';
import Map from "./components/Map";
import Infos from "./components/Infos";
import Specifics from "./components/Specifics";


function App() {
    const params = new URLSearchParams(window.location.search);

    const [actualLanguage, setActualLanguage] = useState(params.get('language')
        ? (params.get('language') === 'en') ? languages[1] : languages[0]
        : languages[0]);

    return (
        <div id="App">
            <Header language={actualLanguage} setLanguage={setActualLanguage}/>

            <Presentation language={actualLanguage}/>

            <div className={"separator"}/>

            <Infos language={actualLanguage}/>

            <Specifics language={actualLanguage}/>

            <div className={"separator"}/>

            <Map language={actualLanguage}/>

            <Footer language={actualLanguage}/>
        </div>
    );
}

export default App;
