import React, {useState} from "react";
import '../styles/Presentation.css';

import { Carousel } from 'react-carousel-minimal';
import useWindowDimensions from "./utils/UseWindowsDimension";


const content = {
    response: {fr: "Temps de réponse : moins d'une heure", en: "Response time: within an hour\n"},
    price: {fr: "à partir de 65€/nuit", en: "from 65€/night"},
    description1: {fr: "Dans un havre de paix, venez vous ressourcer au sein d'un environnement authentique et chaleureux propice à la détente à St Paul de Vence.\n", en: "In a haven of peace, come and recharge your batteries in an authentic and warm environment conducive to relaxation in St Paul de Vence."},
    description2: {fr: "Vous disposerez d'un studio neuf de 24m2 dans une villa avec une entrée indépendante, une terrasse et un jardin privatif au cœur d'un parc de 3500 m2, paysager avec plusieurs espaces de détente et une belle piscine au sel.", en: "You will have a new 24m2 studio in a villa with an independent entrance, a terrace and a private garden in the heart of a 3500 m2 landscaped park with several relaxation areas and a beautiful salt pool."},

}

import profil from '../assets/profil.jpg'
import tel from '../assets/tel.svg'
import cross from '../assets/cross.svg'

// Pictures
import room from '../assets/pictures/chambre.jpg'
import kitchen from '../assets/pictures/kitchen.jpg'
import bathroom from '../assets/pictures/bathroom.jpg'
import bbq from '../assets/pictures/bbq.jpg'
import bed from '../assets/pictures/bed.jpg'
import breakfeast from '../assets/pictures/breakfeast.jpg'
import stpaul2 from '../assets/pictures/stpaul2.jpg'
import stpaul1 from '../assets/pictures/stpaul1.jpg'
import transat from '../assets/pictures/transat.jpg'
import villa1 from '../assets/pictures/villa1.jpg'
import villa2 from '../assets/pictures/villa2.jpg'


function Presentation({ language }) {
    const { width } = useWindowDimensions();
    const [displayContact, setDisplayContact] = useState(false);

    const data = [
        {
            image: room,
        },{
            image: villa1,
        },{
            image: kitchen,
        },{
            image: bathroom,
        },{
            image: villa2,
        },{
            image: bed,
        },{
            image: breakfeast,
        },{
            image: stpaul1,
        },{
            image: stpaul2,
        },{
            image: transat,
        },{
            image: bbq,
        }
    ]

    return (
        <div id={"presentation-container"}>

            <div id={"presentation-header"}>

                <Carousel
                    data={data}
                    width="750px"
                    height="450px"
                    radius="10px"
                    slideNumber={true}
                    dots={true}
                    thumbnails={true}
                    thumbnailWidth="100px"
                    automatic={false}
                    time={50000}
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    captionPosition="bottom"/>

                <div id={"contact-popup"} onClick={() => setDisplayContact(!displayContact)}>
                    { displayContact
                        ? <img src={cross} alt={"Croix"}/>
                        : <img src={tel} alt={"Téléphone"}/>
                    }

                </div>


                {(width >= 1100) || (displayContact)
                    ? <div id={"presentation-contact"}>
                        <div id={"contact-content"}>
                            <img src={profil} alt={"Photo de la propriétaire"}/>
                            <p>Sandra</p>
                            <a href="tel:(33)(650568201)" id={"contact-content-tel"}>
                                <img src={tel} alt={"Téléphone"}/>
                                <p>+33 6 50 56 82 01</p>
                            </a>
                            <p id={"contact-content-infores"}>{content.response[language.index]}</p>
                        </div>
                        <p>{content.price[language.index]}</p>
                    </div>
                    : null
                }
            </div>

            <div id={"presentation-description"}>
                <p>{content.description1[language.index]}</p><br/>
                <p>{content.description2[language.index]}</p>
            </div>
        </div>
    );
}

export default Presentation;